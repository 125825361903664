<template>
  <div>
    <!-- Hero -->
    <base-page-heading title="Setari" subtitle="Editare date personali si schimbare parola">
    </base-page-heading>
    <!-- END Hero -->

    <div class="content">
      <b-row class="row-deck">
        <!-- Latest Customers -->
        <b-col xl="6">
          <base-block title="Editare date personale" header-bg content-full>
            <b-row class="row-deck">
              <b-col xl="8" offset-lg="2">
                <base-block header-bg>
                  <div class="font-size-sm push">
                    <b-form @submit.stop.prevent="onProfileSubmit">
                      <div class="form-group">
                        <b-form-input size="lg" class="form-control-alt" id="firstName" name="firstName" placeholder="Prenume" v-model="$v.profileForm.firstName.$model" :state="!$v.profileForm.firstName.$error && (!errors.profileForm.firstName) && null" aria-describedby="firstName-feedback" @blur="removeServerErrors('profileForm')" @input="removeServerErrors('profileForm')"></b-form-input>
                        <b-form-invalid-feedback v-if="!$v.profileForm.firstName.required" id="firstName-feedback">
                          Campul este obligatoriu!
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="!$v.profileForm.firstName.minLength" id="firstName-feedback">
                          Campul trebuie sa aiba minimum 3 caractere!
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="errors.profileForm.firstName" v-text="errors.profileForm.firstName" id="firstName-feedback"></b-form-invalid-feedback>
                      </div>
                      <div class="form-group">
                        <b-form-input size="lg" class="form-control-alt" id="lastName" name="lastName" placeholder="Nume" v-model="$v.profileForm.lastName.$model" :state="!$v.profileForm.lastName.$error && (!errors.profileForm.lastName) && null" aria-describedby="lastName-feedback" @blur="removeServerErrors('profileForm')" @input="removeServerErrors('profileForm')"></b-form-input>
                        <b-form-invalid-feedback v-if="!$v.profileForm.lastName.required" id="firstName-feedback">
                          Campul este obligatoriu!
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="!$v.profileForm.lastName.minLength" id="firstName-feedback">
                          Campul trebuie sa aiba minimum 3 caractere!
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="errors.profileForm.lastName" v-text="errors.profileForm.lastName" id="firstName-feedback"></b-form-invalid-feedback>
                      </div>
                      <div class="form-group">
                        <b-form-input size="lg" class="form-control-alt" id="email" name="email" placeholder="E-mail" v-model="$v.profileForm.email.$model" :state="!$v.profileForm.email.$error && (!errors.profileForm.email) && null" aria-describedby="email-feedback" @blur="removeServerErrors('profileForm')" @input="removeServerErrors('profileForm')"></b-form-input>
                        <b-form-invalid-feedback v-if="!$v.profileForm.email.email" id="email-feedback">
                          Adresa de email este invalida!
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="errors.profileForm.email" v-text="errors.profileForm.email" id="firstName-feedback"></b-form-invalid-feedback>
                      </div>
                      <b-row class="form-group">
                        <b-col md="6" xl="5" offset-md="6" offset-lg="7">
                          <b-button type="submit" variant="primary" block>
                            <i class="fa fa-fw fa-save mr-1"></i> Salvare
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </div>
                </base-block>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
        <!-- END Latest Customers -->

        <!-- Latest Orders -->
        <b-col xl="6">
          <base-block title="Schimbare parola" header-bg content-full>
            <b-row class="row-deck">
              <b-col xl="8" offset-lg="2">
                <base-block header-bg>
                  <div class="font-size-sm push">
                    <b-form @submit.stop.prevent="onPasswordChangeSubmit">
                      <div class="form-group">
                        <b-form-input type="password" size="lg" class="form-control-alt" id="currentPassword" name="currentPassword" placeholder="Parola curenta" v-model="$v.passwordForm.currentPassword.$model" :state="!$v.passwordForm.currentPassword.$error && (!errors.passwordForm.currentPassword) && null" aria-describedby="currentPassword-feedback" @blur="removeServerErrors('passwordForm')" @input="removeServerErrors('passwordForm')"></b-form-input>
                        <b-form-invalid-feedback v-if="!$v.passwordForm.currentPassword.required" id="currentPassword-feedback">
                          Campul este obligatoriu!
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="!$v.passwordForm.currentPassword.minLength" id="currentPassword-feedback">
                          Campul trebuie sa aiba minimum 6 caractere!
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="errors.passwordForm.currentPassword" v-text="errors.passwordForm.currentPassword" id="firstName-feedback"></b-form-invalid-feedback>
                      </div>
                      <div class="form-group">
                        <b-form-input type="password" size="lg" class="form-control-alt" id="plainPassword" name="plainPassword" placeholder="Parola noua" v-model="$v.passwordForm.plainPassword.$model" :state="!$v.passwordForm.plainPassword.$error && (!errors.passwordForm.plainPassword) && null" aria-describedby="newPassword-feedback" @blur="removeServerErrors('passwordForm')" @input="removeServerErrors('passwordForm')"></b-form-input>
                        <b-form-invalid-feedback v-if="!$v.passwordForm.plainPassword.required" id="newPassword-feedback">
                          Campul este obligatoriu!
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="!$v.passwordForm.plainPassword.minLength" id="newPassword-feedback">
                          Campul trebuie sa aiba minimum 6 caractere!
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="errors.passwordForm.plainPassword" v-text="errors.passwordForm.plainPassword" id="newPassword-feedback"></b-form-invalid-feedback>
                      </div>
                      <div class="form-group">
                        <b-form-input type="password" size="lg" class="form-control-alt" id="passwordConfirm" name="passwordConfirm" placeholder="Repeta parola noua" v-model="$v.passwordForm.passwordConfirm.$model" :state="!$v.passwordForm.passwordConfirm.$error && null" aria-describedby="passwordConfirm-feedback" @blur="removeServerErrors('passwordForm')" @input="removeServerErrors('passwordForm')"></b-form-input>
                        <b-form-invalid-feedback v-if="!$v.passwordForm.passwordConfirm.sameAsPassword" id="passwordConfirm-feedback">
                          Parola nu se potriveste!
                        </b-form-invalid-feedback>
                      </div>
                      <b-row class="form-group">
                        <b-col md="6" xl="5" offset-md="6" offset-lg="7">
                          <b-button type="submit" variant="primary" block>
                            <i class="fa fa-fw fa-exchange-alt mr-1"></i> Schimbare
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </div>
                </base-block>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
        <!-- END Latest Orders -->
      </b-row>
    </div>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {minLength, required, email, sameAs} from "vuelidate/lib/validators";
import {saveUsername, saveToken} from "@/data/logged-in-user";
import { handleFormValidation, fleshErrorMessage } from "@/error/server-error";
import {
  CHANGE_PASSWORD_MUTATION,
  ME_USER_FOR_PROFILE,
  UPDATE_PERSONAL_DATA_MUTATION
} from "../../../constants/user-graphql";

export default {
  name: "UserSetting",
  mixins: [validationMixin],
  data () {
    return {
      profileForm: {
        firstName: null,
        lastName: null,
        email: null,
      },
      passwordForm: {
        currentPassword: null,
        plainPassword: null,
        passwordConfirm: null,
      },
      errors: {
        profileForm: {},
        passwordForm: {},
      },
    }
  },
  validations: {
    profileForm: {
      firstName: {
        required,
        minLength: minLength(3)
      },
      lastName: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      },
    },
    passwordForm: {
      currentPassword: {
        required,
        minLength: minLength(6)
      },
      plainPassword: {
        required,
        minLength: minLength(6)
      },
      passwordConfirm: {
        sameAsPassword: sameAs('plainPassword')
      },
    }
  },
  apollo: {
    meUser: {
      query: ME_USER_FOR_PROFILE,
      fetchPolicy: 'no-cache',
      result (result) {
        this.profileForm.firstName = result.data.meUser.firstName
        this.profileForm.lastName = result.data.meUser.lastName
        this.profileForm.email = result.data.meUser.email
      },
      error (error) {
        fleshErrorMessage(error, this);
      }
    }
  },
  methods: {
    onProfileSubmit () {
      this.errors.profileForm = {};
      this.$v.profileForm.$touch()
      if (this.$v.profileForm.$anyError) {
        return
      }
      this.$apollo.mutate({
        mutation: UPDATE_PERSONAL_DATA_MUTATION,
        variables: {
          'firstName': this.$v.profileForm.firstName.$model,
          'lastName': this.$v.profileForm.lastName.$model,
          'email': this.$v.profileForm.email.$model,
        }
      }).then(() => {
        saveUsername(this.$v.profileForm.firstName.$model+" "+this.$v.profileForm.lastName.$model);
        this.flashMessage.info({message: "Datele personale sunt salvate cu succes!"});
        this.$store.commit('setUsername', this.$v.profileForm.firstName.$model+" "+this.$v.profileForm.lastName.$model);
      }).catch((error) => {
        let form = this.$v.profileForm;
        this.errors.profileForm = handleFormValidation(error, form);
        fleshErrorMessage(error, this);
      })
    },
    onPasswordChangeSubmit () {
      this.errors.passwordForm = {};
      this.$v.passwordForm.$touch()
      if (this.$v.passwordForm.$anyError) {
        return;
      }
      this.$apollo.mutate({
        mutation: CHANGE_PASSWORD_MUTATION,
        variables: {
          'currentPassword': this.$v.passwordForm.currentPassword.$model,
          'plainPassword': this.$v.passwordForm.passwordConfirm.$model
        }
      }).then((response) => {
        saveToken(response.data.createUserChangePassword.userChangePassword.response);
        this.flashMessage.info({message: "Parola a fost schimbat cu succes!"});
        this.passwordForm.currentPassword = null;
        this.passwordForm.plainPassword = null;
        this.passwordForm.passwordConfirm = null;
        this.$v.passwordForm.$reset();
      }).catch((error) => {
        let form = this.$v.passwordForm;
        this.errors.passwordForm = handleFormValidation(error, form);
        fleshErrorMessage(error, this);
      })
    },
    removeServerErrors(form)
    {
      this.errors[form] = {};
    }
  }
}
</script>

<style scoped>

</style>